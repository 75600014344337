import React from "react"
import styled from "@emotion/styled"
import { Heading as ReHeading } from "rebass"

const Hr = styled.hr`
  border-top: ${({ theme }) => `2px solid ${theme.colors.primary}`};
`

export const Heading = ({ children }) => (
  <div>
    <ReHeading fontFamily="body" color="textGrey" mb={3}>
      {children}
    </ReHeading>
    <Hr />
  </div>
)
