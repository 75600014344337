export default {
  locations: [
    {
      key: "1-dorrigo",
      title: "Physiotherapy Dorrigo",
      text: "Dorrigo health and wellness centre",
      linkTo: "/dorrigo",
    },
    {
      key: "2-bellingen",
      title: "Physiotherapy Bellingen",
      text: "Three Rivers Health Bellingen",
      linkTo: "/bellingen",
    },
    {
      key: "3-coffs",
      title: "Physiotherapy Coffs Harbour",
      text: "Coffs Harbour Women’s Health Centre",
      linkTo: "/coffs-harbour",
    },
    {
      key: "4-thora",
      title: "Physiotherapy Thora",
      text: "",
      linkTo: "/thora",
    },
  ],
  services: [
    {
      key: "1-musculosketal",
      title: "Musculoskeletal Physiotherapy",
      serviceTypes: [
        "Physiotherapy for the spine: Back pain, neck pain",
        "Sport injury physiotherapy",
        "Post-operative rehabilitation",
        "Soft tissue & skeletal pain",
        "Work & insurance related Injuries (SIRA-registered)",
        "Pre-operative preparation & post-operative rehabilitation",
        "Movement assessment",
        "Postural assessment & strategy",
        "Massage",
        "General physiotherapy",
      ],
    },
    {
      key: "2-pelvic-health",
      title: "Pelvic Health Physiotherapy",
      serviceTypes: [
        "Women’s & Men’s Health Physiotherapy",
        "Urinary & fecal continence issues: leaking, urgency, frequency & some forms of constipation",
        "Pelvic pain",
        "Sexual function",
        "Birth preparation & recovery",
        "Lactating breast care & mastitis management",
        "Pelvic Organ prolapse assessment, prevention & management",
        "Second Half of Life pelvic assessment & preventative planning",
      ],
    },
    {
      key: "3-age",
      title: "Ageing Ably",
      serviceTypes: [
        "Physiotherapy for middle-age onward",
        "Physiotherapy for elderly",
        "Strategies for arthritis management",
        "Movement assessment & strategies",
      ],
    },
  ],
}
