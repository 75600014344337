import React from "react"
import { Parallax } from "react-parallax"
import { Helmet } from "react-helmet"
import styled from "@emotion/styled"
import { Tiles } from "@rebass/layout"
import { Card, Flex, Text, Box, Button, Heading as Reheading } from "rebass"
import { Header } from "../components/header"
import { Footer } from "../components/footer"
import { Heading } from "../components/heading"
import data from "../data/home"
import background from "../images/image.jpg"
import l from "../images/logo.png"
import rings from "../images/rings.png"

const LogoImage = styled.img`
  max-width: 140px;
  height: auto;
`

const Container = styled(Box)`
  max-width: 900px;
  margin: 0 auto;
  padding-top: ${({ theme }) => theme.space[4]}px;
  padding-bottom: ${({ theme }) => theme.space[4]}px;
`
const Wrapper = styled(Box)``

const Circle = styled(Box)`
  background-image: url(${rings});
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  box-shadow: 0px 3px 6px #00000029;
`

export default () => {
  return (
    <>
      <Helmet>
        <title>Melanie Couper Physiotherapy</title>
        <meta
          name="description"
          content="Melanie Couper is a physiotherapist operating in Bellingen, Coffs Harbour, Dorrigo, and Thora. Mel Couper is committed to empowering people to journey well in their body during all phases of life."
        />
      </Helmet>
      <Header />
      <Parallax
        bgImage={background}
        bgImageAlt="Dorrigo Mountain horizon."
        strength={200}
        bgImageStyle={{ objectFit: "cover" }}
      >
        <Flex height="300px" flexDirection="column" justifyContent="center">
          <Flex
            width="50%"
            mx="auto"
            justifyContent="center"
            alignItems="center"
            flexDirection={["column", "column", "row"]}
          >
            <LogoImage src={l} />
            <Box
              ml={[0, 0, 3]}
              maxWidth={300}
              textAlign={["center", "center", "left"]}
            >
              <Reheading
                color="white"
                fontFamily="heading"
                lineHeight="heading"
                fontWeight="bodyHeavy"
                fontSize={5}
                mb={3}
                display={["none", "inline"]}
              >
                Melanie Couper Physiotherapy
              </Reheading>
              <Text
                color="#A0BCC4"
                fontSize={2}
                fontStyle="italic"
                fontFamily="body"
                lineHeight="1.3"
              >
                Empowering people to journey well in their body through all
                phases of life
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Parallax>
      <Container px={3}>
        <Heading>Locations</Heading>
        <Wrapper mx="auto" maxWidth={[500, 500, 900]}>
          <Tiles columns={[1, 1, 2]} mb={5}>
            {data.locations.map(({ key, title, text, linkTo }, index) => (
              <Card
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="center"
                maxWidth={442}
                key={key}
                bg={
                  index !== 0 && index !== data.locations.length - 1
                    ? "primary"
                    : "jade"
                }
              >
                <div>
                  <Reheading color="white" fontSize={3} mb={2}>
                    {title}
                  </Reheading>
                  <Text color="lightGrey" fontFamily="body" mb={3}>
                    {text}
                  </Text>
                </div>
                <Button variant="primary" as="a" href={linkTo}>
                  More info
                </Button>
              </Card>
            ))}
          </Tiles>
        </Wrapper>

        <Heading>Services</Heading>

        <Tiles columns={[1, 3]}>
          {data.services.map(({ key, title, serviceTypes }) => (
            <Card variant="stack" key={key} mb={[4, 0]}>
              <Circle variant="circle" mb={[3, 4]}></Circle>
              <Reheading as="h3" color="textGrey" fontSize={2} mb={3}>
                {title}
              </Reheading>
              {serviceTypes.map(serviceType => (
                <Text
                  color="textGrey"
                  fontSize={1}
                  lineHeight="1.3"
                  fontFamily="body"
                >
                  - {serviceType}
                </Text>
              ))}
            </Card>
          ))}
        </Tiles>
      </Container>
      <Footer />
    </>
  )
}
